import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useState} from 'react';
import NavScrollExample from './components/NavScrollExample';

function App() {
  const [ page, setPage ] = useState('Home');
  return (
    <div className="App">
      <NavScrollExample page={page} setPage={setPage}></NavScrollExample>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>
          {page} Page
        </h1>
        <p>Oops! Nothing here yet...</p>
      </header>
    </div>
  );
}

export default App;
