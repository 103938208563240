
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import icon from '../images/icon.png'

function NavScrollExample({page,setPage}) {
  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container fluid>
      <Navbar.Brand href="#" style={{alignItems: 'center', display: 'flex', marginLeft: '1em'}}>
  <img
    src={icon}
    width="40"
    height="40"
    className="d-inline-block align-top"
    alt="React Bootstrap logo"
    style={{ marginRight: '10px' }}
  />
  <h2 className="d-inline-block align-top" style={{ margin: '0' }}>SYX</h2>
</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll" className="w-100" style={{marginRight:'1em'}}>
          <div className="d-flex justify-content-end w-100">
            <Nav
              className="my-2 my-lg-0"
              style={{ maxHeight: '100px'}}
              navbarScroll
            >
              <Nav.Link href="#action1" onClick={()=>setPage('Home')}>Home</Nav.Link>
              <Nav.Link href="#action2" onClick={()=>setPage('About Me')}>About Me</Nav.Link>
              
              <Nav.Link href="#" onClick={()=>setPage('Achievements')}>
                Achievements
              </Nav.Link>
              <NavDropdown title="CTF Writeups" id="navbarScrollingDropdown" disabled>
                <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action4">Another action</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action5">
                  Something else here
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}



export default NavScrollExample;